import { render, staticRenderFns } from "./HomeResume.vue?vue&type=template&id=30d826ab&scoped=true&functional=true&"
var script = {}
import style0 from "./HomeResume.vue?vue&type=style&index=0&id=30d826ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "30d826ab",
  null
  
)

export default component.exports