import { render, staticRenderFns } from "./HomeAbout.vue?vue&type=template&id=05110699&scoped=true&functional=true&"
var script = {}
import style0 from "./HomeAbout.vue?vue&type=style&index=0&id=05110699&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "05110699",
  null
  
)

export default component.exports